import en from '../locale/en.json';
import ar from '../locale/ar.json';
import axios from "axios";
const translations = {
    en,
    ar
}
const storageKey = 'locale';
const dataTranslationKey = 'data-translation';
function updateHtmlElement(locale) {
    const htmlElement = document.querySelector('html');
    const dir = locale === 'ar' ? 'rtl' : 'ltr';
    htmlElement.setAttribute('dir',dir);
    htmlElement.setAttribute('lang',locale);
}
function toggleLocale() {
    const currentLocale = localStorage.getItem(storageKey) || 'ar';
    const newLocale = currentLocale === 'ar' ? 'en' : 'ar';
    localStorage.setItem(storageKey,newLocale)
    updateTranslationsValues(newLocale)
    updateHtmlElement(newLocale)
}
function updateTranslationsValues(locale)  {
    const elements = document.querySelectorAll('[data-translation]');

    elements.forEach(element => {
        const translationKey =element.getAttribute(dataTranslationKey);
        element.innerHTML = translations[locale][translationKey];
    })
}
function initLang() {
    const currentLocale = localStorage.getItem(storageKey) || 'ar';
    updateTranslationsValues(currentLocale)
    updateHtmlElement(currentLocale)
}
function onSubmit(e) {
    e.preventDefault()
    const emailInput =document.getElementsByName('email')[0]
    const email =emailInput.value;
    axios.post('https://esso-api.910ths.sa/api/service/bahr/email', {
        email,
        source:window.location.origin
    })
        .then(function (response) {
            emailInput.value='';
        })
}

const form = document.querySelector("form");
form.addEventListener("submit", onSubmit);
window.onload = initLang
window.toggleLocale = toggleLocale
window.onSubmit = onSubmit